import { createCtx } from "lib/context";

import { UserDetails } from "./interfaces";

const [loginCtx, loginFlagProvider] = createCtx<{
  loggedIn: boolean;
  inProgress: boolean;
}>({ loggedIn: false, inProgress: true });

export const LoginFlagContext = loginCtx;
export const LoginContextProvider = loginFlagProvider;

const [userCtx, userDetailsProvider] = createCtx<UserDetails>(null);

export const UserDetailsContext = userCtx;
export const UserContextProvider = userDetailsProvider;
