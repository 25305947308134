import { useContext, useEffect } from "react";

import { getDaishoUser } from "./api";
import { LoginFlagContext, UserDetailsContext } from "./context";

export const useGetUser = () => useContext(UserDetailsContext).state;

export const useUpdateUser = () => {
  const update = useContext(UserDetailsContext).update;
  useEffect(() => {
    getDaishoUser().then(update);
  }, []);

  return useGetUser();
};

export const useGetLoginStatus = () => {
  // const user = useGetUser();
  return useContext(LoginFlagContext).state.loggedIn;
};

export const useGetLoginInProgress = () =>
  useContext(LoginFlagContext).state.inProgress;
