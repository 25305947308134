import { useCallback, useContext } from "react";

import { getApps } from "firebase/app";
import { User } from "firebase/auth";

import { auth } from "lib/firebase/firebase";
import { LoginFlagContext, UserDetailsContext } from "lib/auth";

import { UserDetails } from "./interfaces";

export const getDaishoUser = async (user?: User) => {
  const url = process.env.NEXT_PUBLIC_DAISHO_USER_PROFILE_URL;
  if (!url) {
    throw new Error("Profile URL Not Passed");
  }
  try {
    let options: RequestInit = {};
    if (user) {
      // Force refresh: Every call ensures the id-token is refreshed, and the user's still logged-in.
      const idToken = await user.getIdToken(true);

      const firebaseUserDetails = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        photoURL: user.photoURL,
        uid: user.uid,
        phoneNumber: user.phoneNumber,
        providerData: user.providerData,
      };

      options.method = "POST";
      options.mode = "cors";
      options.body = JSON.stringify({
        idToken,
        userDetails: firebaseUserDetails,
      });
      options.credentials = "include";
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    const resp = await fetch(url, options);
    const d = (await resp.json()) as UserDetails;
    return d;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const useGetLogout = () => {
  const { update: updateLoginFlag } = useContext(LoginFlagContext);
  const { update: updateUser } = useContext(UserDetailsContext);
  const ready = getApps().length >= 1;

  const logout = useCallback(async () => {
    updateLoginFlag({ loggedIn: false, inProgress: false });
    updateUser(null);

    if (ready) {
      Promise.all([
        auth.signOut(),
        fetch(process.env.NEXT_PUBLIC_DAISHO_LOGOUT_URL, {
          method: "POST",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }),
      ]);
    }
  }, [ready, updateLoginFlag, updateUser]);

  return logout;
};
