import { LoginContextProvider, UserContextProvider } from "lib/auth";

const AuthShell = ({ children }) => {
  return (
    <LoginContextProvider>
      <UserContextProvider>{children}</UserContextProvider>
    </LoginContextProvider>
  );
};

export default AuthShell;
