const url = process.env.NEXT_PUBLIC_DAISHO_CUSTOMER_ID_URL;
const DAISHO_STRIPE_HOOK_KEY = process.env.DAISHO_STRIPE_HOOK_KEY;

export const getCustomerId = async () => {
  try {
    const resp = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ signing_key: DAISHO_STRIPE_HOOK_KEY }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (resp.status >= 400) {
      return null;
    }
    const data = await resp.json();
    return data.customer_id as string;
  } catch (e) {
    return null;
  }
};
